import React  from "react"
import { Link } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"

import styles from "./madetoorder.module.scss"

const MadeToOrder2 = ({ location }) => {

  return (
    <>
      <SEO
        title="Made To Order - It’s Happening"
        description="Made To Order"
        url={location.pathname}
      />

      <header className={styles.header}>
        <nav className={styles.nav}>
          <Heading
            tag="h2"
            level="2"
            className={styles.navHeading}
          >
            Made to Order
          </Heading>
          <ul className={styles.navList}>
            <li>
              <Link to="/made-to-order/chapter-1" className={styles.navLinkPrev}>1</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-2" className={styles.navLinkActive}>2</Link>
            </li>
            <li>
              <Link to="/made-to-order/chapter-3" className={styles.navLinkNext}>3</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.headingContainer}>
          <h3 className={styles.chapterHeading}>CHAPTER 2</h3>
          <Heading
            tag="h1"
            level="1"
            className={styles.heading}
          >
            It’s Happening
          </Heading>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_1_Desktop_v2_1320x.jpg?v=1615236241",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_1_Mobile_v2_850x.jpg?v=1615236241",
          }}
          altText="Chapter 2"
        />
      </header>

      <section className={styles.section}>
        <div>
          <p>The team in Italy have received the materials to make your shoes and they’ve begun the painstaking process of breathing life into your pair. From the first cut to the final stitch, no detail is overlooked to guarantee that each step is completed with the utmost precision.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_2_1320x.jpg?v=1615114401",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_2_850x.jpg?v=1615114401",
          }}
          altText="Chapter 2 - Part 1"
        />
      </section>

      <section className={styles.section}>
        <div>
          <p>We take pride in the fact that there is no length we won’t go to in order to create the most beautiful and well-made shoes on the market. Once the finishing touches are applied, our specialists will examine every single millimeter as a final quality check before they make their journey home to you.</p>
        </div>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_3_1320x.jpg?v=1615114400",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Phase2_3_850x.jpg?v=1615114400",
          }}
          altText="Chapter 2 - Part 2"
        />
      </section>
    </>
  )
}

export default MadeToOrder2
